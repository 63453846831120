<template>
    <div class="box f_color1 font2">
      <van-nav-bar title="二级推广组列表" left-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
      <van-search v-if="!!selfInfo" v-model="search" @search="onSearch" shape="round" show-action placeholder="输入组长ID">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <van-radio-group v-model="checked" class="search-id-type" direction="horizontal">
        <van-radio name="0" icon-size="14px" class="font1">组长ID</van-radio>
        <van-radio name="1" icon-size="14px" class="font1">组ID</van-radio>
      </van-radio-group>
      <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
      <van-list v-if="!!selfInfo" class="list font2" v-model:loading="loading" :immediate-check="false"
        :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list-item" v-for="(li, i) in list" :key="i">
          <div class="head">
            <div class="images">
              <img :src="li.userInfo.portrait ? config.fileServerUrl + li.userInfo.portrait : muser" alt="" />
            </div>
            <div class="names">
              <p class="nickname font2 f_color1">
                {{ li.userInfo.nickName }}
              </p>
              <p class="userid font4 f_color2">
                加入时间：{{ getDisplayJoinTime(li.joinTime) }}
              </p>
            </div>
            <!-- <div v-if="li.userId !== selfInfo.userId && li.status != 2" class="status">
              <div v-if="li.status == 1" class="enable" @click="onEnableTapped(li)">
                启用
              </div>
              <div v-else class="disable" @click="onDisableTapped(li)">
                禁用
              </div>
            </div> -->
          </div>
          <div class="body lines_bottom">
            <div class="part" @click="onUidTapped(li)">
              <div class="value">
                {{ li.userId }}
              </div>
              <div class="key">
                组长ID
              </div>
            </div>
            <div class="part" @click="onInviteCodeTapped(li)">
              <div class="value">
                {{ li.code }}
              </div>
              <div class="key">
                组ID
              </div>
            </div>
            <div class="part" @click="onParentIdTapped(li)">
              <div class="value">
                {{ li.num }}
              </div>
              <div class="key">
                人数
              </div>
            </div>
          </div>
          <div class="foot">
            <div class="left lines_right" @click="onCopyUserIdTapped(li)">
              复制组长ID
            </div>
            <div class="right" @click="onIncomeTapped(li)">
              查看流水
            </div>
          </div>
        </div>
      </van-list>
      <!--            </van-pull-refresh>-->
      <van-popup v-model:show="confirm" :close-on-click-overlay="true">
        <div class="confirm">
          <div class="confirm-content">是否确认{{ isEnable ? '启用' : '禁用' }}该用户？</div>
          <van-button type="success" round size="small" @click="onConfirmTapped">确认</van-button>
        </div>
      </van-popup>
      <Mask v-if="marke"></Mask>
    </div>
  </template>
  <script>
  import muser from "@/assets/image/user.png";
  // import { wxqq } from '../utils/utils'
  import Mask from '../components/Mask'
  import routerUtil from '../utils/routerUtil'
  import { Toast } from "vant";
  import copy from 'copy-to-clipboard';

  export default {
    name: 'MemberList',
    data() {
      return {
        search: '',
        checked: '0',
        muser,
        selfInfo: null,
        groupInfo: null,
        searchType: 0,
        searchUid: null,
        loading: false,
        finished: false,
        refreshing: false,
        list: [],
        confirm: false,
        item: null,
        isEnable: false,
        marke: false,
      }
    },
    components: {
      Mask,
    },
    computed: {
      config: () => {
        return JSON.parse(localStorage.getItem("config"));
      },
    },
    mounted() {
      this.reqInfo()
      // this.marke = wxqq()
    },
    methods: {
      reqInfo() {
        Promise.all([this.$request.post('/api/user/mine/userInfo'), this.$request.postJson('/api/user/pay_group/info', {})])
          .then((values) => {
            if (values[0].data.code == 0 && values[1].data.code == 0) {
              this.selfInfo = values[0].data.data;
              this.groupInfo = values[1].data.data;
              if (!this.groupInfo) {
                Toast('暂无推广组信息');
                routerUtil.back()
                return;
              }
              this.getList()
            }
          });
      },
      getList(type) {
        // eslint-disable-next-line no-debugger
        debugger
        let lastId = null
        if (type && this.list.length > 0) {
          lastId = this.list[this.list.length - 1].id
        }
        this.$request.postJson('/api/user/pay_group/member/twolist', {
          groupId: this.groupInfo.id,
          type: this.searchType,
          userId: this.searchUid == null ? 0 : this.searchUid,
          lastId: lastId,
          pageSize: 15
        }).then((res) => {
          console.log('二级推广组====>',res)
          if (res.data.code == 0) {
            if (res.data.data.users.length == 0) {
              this.loading = false;
              this.refreshing = false
              this.finished = true;
              return
            }
            const uids = [];
            for (let user of res.data.data.users) {
              uids.push(user.userId);
              console.log('用户ID',uids)
            }
            this.$request.post('/api/user/info/userInfo', { userIds: JSON.stringify(uids) })
              .then((res1) => {
                this.loading = false
                this.refreshing = false
                if (res1.data.code == 0) {
                  const userMap = new Map();
                  for (let user of res1.data.data.users) {
                    if (user.code == 0) {
                      userMap.set(user.userId, user);
                    } else {
                      Toast("请求用户基本信息失败")
                      return;
                    }
                  }
                  for (let user of res.data.data.users) {
                    user.userInfo = userMap.get(user.userId);
                  }
                  this.list.push(...res.data.data.users)
                  this.finished = res.data.data.users.length < 15
                }
              })
          }
        })
      },
      onClickLeft() {
        routerUtil.back()
      },
      onClickRight() {
      },
      onSearch() {
        if (this.search == "") {
          this.searchUid = null
        } else {
          if (isNaN(Number(this.search, 10))) {
            Toast("请输入组长ID")
            return
          }
          const uid = parseInt(this.search);
          this.searchUid = uid;
        }
        this.searchType = parseInt(this.checked)
        this.list = [];
        this.onRefresh()
      },
      onLoad() {
        this.getList(true)
      },
      onRefresh() {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.getList()
      },
      onEnableTapped(li) {
        this.confirm = true;
        this.item = li;
        this.isEnable = true;
      },
      onDisableTapped(li) {
        this.confirm = true;
        this.item = li;
        this.isEnable = false;
      },
      onConfirmTapped() {
        this.confirm = false;
        const status = this.isEnable ? 0 : 1;
        this.$request.postJson('/api/user/pay_group/member/change_status', {
          groupId: this.groupInfo.id,
          userId: this.item.userId,
          status: status
        }).then((res) => {
          if (res.data.code == 0) {
            this.item.status = status
          }
        })
      },
      onUidTapped(li) {
        copy(li.userId);
        Toast('组长ID已复制');
      },
      onInviteCodeTapped(li) {
        copy(li.code);
        Toast('组ID已复制');
      },
      onParentIdTapped(li) {
        if (!li.parentId) {
          return
        }
        copy(li.parentId);
        Toast('上级ID已复制');
      },
      onCopyUserIdTapped(li) {
        this.onUidTapped(li)
      },
      onIncomeTapped(li) {
        this.$router.push({
          name: 'twolevel',
          params: {
            userId: li.userId
          }
        });
      },
      getDisplayJoinTime(time) {
        const date = new Date(time);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hour = date.getHours();
        const min = date.getMinutes();
        const sec = date.getSeconds();
        return `${date.getFullYear()}/${month > 9 ? month : '0' + month}/${day > 9 ? day : '0' + day} ${hour > 9 ? hour : '0' + hour}:${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`;
      }
    },
  }
  </script>
  <style scoped lang="less">
  .box {
    width: 100%;
    height: 100%;

    .search-id-type {
      margin-left: 12px;
      padding-bottom: 10px;
    }
  }

  .list {
    position: absolute;
    width: 100%;
    padding-bottom: 80px;
    background-color: #f6f6f6;

    .list-item {
      width: calc(100% - 16px - 16px);
      margin-left: 16px;
      margin-top: 16px;
      height: 188px;
      background-color: #fff;
      border-radius: 6px;

      .head {
        position: relative;
        width: calc(100% - 10px - 10px);
        height: 60px;
        padding: 10px 10px 20px 10px;

        .images {
          display: inline-block;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .names {
          display: inline-block;
          height: 40px;
          position: absolute;
          margin-top: 10px;

          .nickname {
            height: 16px;
            line-height: 16px;
          }

          .userid {
            margin-top: 10px;
            height: 14px;
            line-height: 14px;
          }
        }

        .status {
          float: right;
          margin-right: 30px;
          font-size: 16px;
          height: 60px;
          line-height: 60px;

          .enable {
            color: #6b94d9;
          }

          .disable {
            color: #c95058;
          }
        }
      }

      .body {
        position: relative;
        width: 100%;
        height: 50px;

        .part {
          display: inline-block;
          width: calc(100% / 3);
          height: 50px;

          .key {
            margin-top: 6px;
            font-size: 12px;
            height: 12px;
            line-height: 12px;
            color: #bdbdbd;
            text-align: center;
          }

          .value {
            font-size: 14px;
            font-weight: bold;
            height: 14px;
            line-height: 14px;
            color: #787878;
            text-align: center;
          }
        }
      }

      .foot {
        position: relative;
        width: 100%;
        height: 48px;
        font-size: 14px;
        line-height: 48px;

        .left {
          display: inline-block;
          width: calc(100% / 2);
          color: #6b94d9;
          text-align: center;
        }

        .right {
          display: inline-block;
          width: calc(100% / 2);
          color: #f29a38;
          text-align: center;
        }
      }
    }
  }

  .van-pull-refresh {
    height: 100% !important;
    overflow: auto !important;
  }

  .van-list {
    height: auto !important;
  }

  .confirm {
    width: 200px;
    height: auto;
    padding: 20px;
    text-align: center;

    .confirm-content {
      font-size: 14px;
      color: #666;
    }

    button {
      width: 100px;
      margin-top: 20px;
    }
  }
  </style>
